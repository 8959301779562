@import url('https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/*================== 
theme color 
==================*/
:root {
    --primary-color: #00457c;
    --title-color: #1d1e1b;
}

ul,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
p,
address {
    margin: 0;
    padding: 0;
}

/* li {
    list-style: none;
} */
a {
    text-decoration: none;
}
.containers {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Poppins', sans-serif;
    /* font-family: 'Epilogue', sans-serif; */
}

/* banner css start */
.bannerContainer {
    width: 100%;
    background-image: url('../../images/lp6/home_bg.png');
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
    background-color: #f9fbfd;
    padding-top: 6rem;
    padding-bottom: 2rem;
}
.bannerFlex {
    display: flex;
    align-items: center;
    gap: 4rem;
}
.bannerFlexLeft {
    width: 50%;
}
.bannerFlexRight {
    width: 50%;
}

.titleText {
    font-size: 42px;
    font-weight: 700;
    line-height: 52px;
    font-family: 'Epilogue', sans-serif;
    color: var(--primary-color);
}
.contentText {
    font-size: 16px;
    line-height: 24px;
    color: #605c78;
    font-weight: 500;
}
.bannerBtn {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 5rem;
}
.bannerBtnimg {
    max-width: 194px;
    object-fit: cover;
}

/* clinet css */
.clientContainer {
    padding-top: 5rem;
    padding-bottom: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10rem;
}
.clinetTitle {
    color: #1d1e1b;
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    width: 50%;
}
.clientContent {
    width: 50%;
    display: flex;
    align-items: center;
    gap: 2rem;
}
.userImg {
    max-width: 180px;
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
    display: inline-block;
    margin-left: -20px;
}
.clientContentLeft {
    /* box-shadow: 0px 4px 4px -4px #0c0c0d0d; */
    box-shadow: 0px 16px 32px -4px #0c0c0d1a;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 1rem;
}
.ratingNumber {
    background: #0079c1;
    color: #fff;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 0px !important;
    padding: 30px 20px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

/* app section css */
.appContainer {
    background-color: #f9fbfd;
    padding-top: 6rem;
    padding-bottom: 6rem;
}

.appFlex {
    display: flex;
    gap: 4rem;
}
.appContent {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}
.workContainer {
    padding-top: 5rem;
    background-image: url('../../images/lp6/one_side-bg.png');
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
}
.workGird {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    margin-top: 6rem;
}

.step {
    text-align: center;
    padding: 20px;
    border-radius: 8px;
    background-color: #edf4f8;
    flex: 1;
    position: relative;
}
.step > h2 {
    margin: 0;
    font-size: 32px;
    font-weight: 700;
    color: #fff;
    background-color: #00457c;
    font-family: 'Epilogue', sans-serif;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    border: 5px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 39%;
    top: -40px;
}
.step > h3 {
    margin: 30px 0 0 0;
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    color: #00457c;
    text-align: center;
}
.step > p {
    margin: 6px 0 0 0;
    font-size: 16px;
    color: #72737c;
    font-weight: 400;
    text-align: center;
}
.lookContainer {
    background-image: url('../../images/lp6/two_side_bg.png');
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
    background-color: #00457c;
    padding-top: 6rem;
}

.lookFlex {
    display: flex;
    align-items: center;
}
.lookFlexLeft {
    width: 40%;
}
.lookFlexRight {
    width: 60%;
}

.boxs {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 90px;
    border-radius: 0;
    z-index: 100000;
    color: white;
    transition: bottom 0.5s ease-in-out;
    
}

.buttons {
    background: #00457c;
    text-transform: capitalize;
    font-family: 'Epilogue', sans-serif;
    color: white;
    font-weight: 700;
    font-size: 20px;
    max-width: 500px;
    padding: 16px 48px;
    border: 0;
    border-radius: 40px;
    cursor: pointer;
    transition: transform 0.2s ease-out;
    animation: blink 1.5s infinite; /* Add animation */
}

@keyframes blink {
    0% { background-color: #00457c; }
    50% { background-color: #ff9900; }
    100% { background-color: #00457c; }
}

.buttons:hover {
    transform: scale(1.05);
}
/* small device  */

@media only screen and (min-width: 250px) and (max-width: 575px) {
    .containers {
        max-width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .bannerFlex,
    .appFlex {
        display: flex;
        flex-direction: column;
    }
    .bannerFlexRight {
        width: 100%;
    }
    .bannerFlexLeft {
        width: 100%;
    }
    .bannerBtnimg {
        max-width: 140px;
    }
    .bannerBtn {
        margin-top: 3rem;
    }
    .titleText {
        font-size: 30px;
        line-height: 38px;
    }
    .bannerContainer {
        padding-top: 3rem;
    }
    .clientContainer {
        display: flex;
        flex-direction: column;
        gap: 3rem;
    }
    .clinetTitle {
        width: 100%;
    }
    .clientContent {
        width: 100%;
    }
    .workGird {
        grid-template-columns: 1fr;
        gap: 4rem;
        padding-top: 3rem;
    }
    .appContainer {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
    .lookFlex {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 3rem;
    }
    .lookFlexLeft {
        width: 100%;
        text-align: center;
    }
    .lookFlexRight {
        width: 100%;
    }
    .lookContainer {
        padding-top: 3rem;
    }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
    .containers {
        max-width: 100%;
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .bannerFlex,
    .appFlex {
        display: flex;
        flex-direction: column;
    }
    .bannerFlexRight {
        width: 100%;
    }
    .bannerFlexLeft {
        width: 100%;
    }
    .bannerBtn {
        margin-top: 3rem;
    }
    .bannerBtnimg {
        max-width: 150px;
    }
    .titleText {
        font-size: 30px;
        line-height: 38px;
    }
    .bannerContainer {
        padding-top: 3rem;
    }
    .clientContainer {
        display: flex;
        flex-direction: column;
        gap: 3rem;
    }
    .clinetTitle {
        width: 100%;
    }
    .clientContent {
        width: 100%;
    }
    .workGird {
        grid-template-columns: 1fr 1fr;
        gap: 4rem;
        padding-top: 3rem;
    }
    .appContainer {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
    .lookFlex {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 3rem;
    }
    .lookFlexLeft {
        width: 100%;
        text-align: center;
    }
    .lookFlexRight {
        width: 100%;
    }
    .lookContainer {
        padding-top: 3rem;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .containers {
        max-width: 100%;
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .bannerFlex,
    .appFlex {
        display: flex;
        flex-direction: column;
    }
    .bannerFlexRight {
        width: 100%;
    }
    .bannerFlexLeft {
        width: 100%;
    }
    .workGird {
        grid-template-columns: 1fr 1fr;
        gap: 4rem;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .containers {
        max-width: 100%;
        padding-left: 2rem;
        padding-right: 2rem;
    }
}
