@import url('https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100..900;1,100..900&display=swap');
/*================== 
theme color 
==================*/
:root {
    --primary-color: #1570ef;
    --title-color: #000000;
    --text-color-1: #282828;
    --text-color-2: #fcfcfd;
}

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

ul,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
p,
address {
    margin: 0;
    padding: 0;
}

/* li {
    list-style: none;
} */
a {
    text-decoration: none;
}

.containers {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Epilogue', sans-serif;
}

.btnsLink {
    background-color: var(--primary-color);
    color: var(--text-color-2) !important;
    border: none;
    border-radius: 8px;
    padding: 12px 25px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
}

/* banner css start */

.bannerBg {
    background-color: #f6f6f6;
    padding-top: 5rem;
    padding-bottom: 5rem;
}
.bannerContent {
    display: flex;
    align-items: center;
    gap: 3rem;
}
.bannerLeft {
    width: 50%;
    position: relative;
}

.activeContent {
    color: var(--primary-color);
    font-size: 22px;
    font-weight: 800;
    margin-bottom: 2rem;
}

.titleText {
    font-size: 36px;
    font-weight: 700;
    line-height: 50px;
    font-family: 'Epilogue', sans-serif;
    color: #000000;
}
.titleTextColor {
    color: var(--primary-color);
}
.numberJob {
    font-size: 60px;
}
.lineImg {
    max-width: 80px;
    position: absolute;
    right: 0px;
    top: 11rem;
}
.lineImg > img {
    max-width: 100%;
}
.bannerBtn {
    margin-top: 3rem;
}
.bannerBtn > a {
    display: inline-block;
    width: 100%;
    text-align: center;
}
.bannerRight {
    width: 50%;
}
.videoContainer {
    width: 100%;
    height: 300px;
    overflow: hidden;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
}

.videoContainer > video {
    width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.submitBtn {
    background-color: var(--primary-color);
    border: 0px;
    color: #ffffff;
    width: 100%;
    padding: 12px 25px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 20px;
}
.buttonContent {
    margin-top: 20px;
}
.googleBtn {
    background-color: #d1e9ff;
    border: 0px;
    color: var(--primary-color);
    width: 100%;
    padding: 12px 25px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
}
.googleBtn > img {
    max-width: 20px;
}
.loginContent {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-top: 20px;
}
.loginContent > p {
    font-size: 14px;
    color: #98a2b3;
}
.loginContent > a {
    font-size: 14px;
    color: var(--primary-color);
}

/* banner css end */

/* slider css start */

.sliderContainer {
    margin-top: 5rem;
}
.sliderWrapper {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 2rem;
}
.sliderImg {
    max-width: 150px;
}
.sliderImg > img {
    max-width: 100%;
}

/* slider css end */

/* about css start */
.aboutContainer {
    background-image: url('../../images/lp3/img/Blob.png');
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
}
.aboutFlex {
    display: flex;
    align-items: center;
    gap: 3rem;
    padding-top: 5rem;
}
.aboutLeft {
    width: 45%;
}
.aboutLeft > img {
    width: 100%;
}
.aboutRight {
    width: 55%;
}
.aboutContent {
    margin-top: 2rem;
}
.aboutCard {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.aboutCardImg {
    max-width: 50px;
}
.aboutCardContent {
    color: var(--text-color-1);
}
.aboutCardContent > h4 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0px;
    margin-top: 25px;
    color: #000000;
}
.aboutCardContent > p {
    font-size: 14px;
}
/* about css end */

/* client review css start */
.clinetContainer {
    background-image: url('../../images/lp3/client/client-bg.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 10rem;
    padding-bottom: 5rem;
}
.clientFlex {
    display: flex;
    align-items: center;
    gap: 3rem;
}
.clientLeft {
    width: 30%;
}
.clientLeft > p {
    margin-top: 120px;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-color-1);
}
.clientRight {
    width: 70%;
}
.clientGird {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}
.clientCard {
    display: flex;
    align-items: center;
    gap: 10px;
}
.clientImg {
    height: 80px;
    width: 80px;
    border-radius: 50%;
}
.clientImg > img {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
}
.clientName {
    font-size: 16px;
    font-weight: 700;
    color: var(--text-color-1);
    margin-bottom: 0px;
}
.clientDig {
    margin-bottom: 0px;
    font-size: 14px;
    color: var(--text-color-1);
    font-weight: 400;
}
.clientStart {
    margin-bottom: 3px;
    display: flex;
    align-items: center;
    gap: 4px;
}
.clientStart > img {
    max-width: 15px;
}
.clientStart > i {
    color: #ffa722;
    font-size: 14px;
}
.clientText {
    color: var(--text-color-1);
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 24px;
}
.clientGirdCard {
    background-color: #fff;
    box-shadow: 3px 5.05px 6.51px 3px #c8c8c840;
    padding: 18px;
    border-radius: 8px;
}
/* client review css end */

/* job css start */
.jobContainer {
    background-image: url('../../images/lp3/img/bg-image.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 350px;
    border-radius: 15px;
    margin-bottom: 5rem;
    padding: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.jobTitle {
    color: var(--text-color-2);
    text-align: center;
}
.jobContent {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    margin-top: 20px;
    flex-wrap: wrap;
}
.jobText {
    display: flex;
    align-items: center;
    gap: 8px;
}
.jobText > img {
    max-width: 20px;
}
.jobContent > span {
    color: var(--text-color-2);
    font-size: 15px;
}
.jobBtn {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 30px;
}
/* job css end */

/* footer added */
.boxs {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 90px;
    background-color: #00457c;
    z-index: 100000;
    color: white;
    transition: bottom 0.5s ease-in-out;
}

.buttons {
    background: #ffffff;
    text-transform: capitalize;
    font-family: 'Epilogue', sans-serif;
    font-weight: 700;
    font-size: 25px;
    max-width: 500px;
    padding: 6px 70px;
    border: 0;
}

/* small device  */

@media only screen and (min-width: 250px) and (max-width: 575px) {
    .containers {
        max-width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .bannerContent {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .bannerLeft {
        width: 100%;
    }
    .bannerRight {
        width: 100%;
    }
    .aboutFlex {
        display: flex;
        flex-direction: column;
        gap: 3rem;
    }
    .aboutLeft {
        width: 100%;
    }
    .aboutRight {
        width: 100%;
    }
    .titleText {
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        color: #000000;
        font-family: 'Epilogue', sans-serif;
    }
    .numberJob {
        font-size: 26px;
    }
    .lineImg {
        display: none;
    }
    .activeContent {
        margin-bottom: 1rem;
        font-size: 18px;
    }
    .clientFlex {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
    .clientLeft {
        width: 100%;
    }
    .clientRight {
        width: 100%;
    }
    .clinetContainer {
        padding-top: 5rem;
    }
    .clientLeft > p {
        margin-top: 15px;
    }
    .clientGird {
        grid-template-columns: 1fr;
    }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
    .containers {
        max-width: 100%;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .bannerContent {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .bannerLeft {
        width: 100%;
    }
    .bannerRight {
        width: 100%;
    }
    .lineImg {
        display: none;
    }
    .titleText {
        font-size: 28px;
        font-weight: 700;
        line-height: 38px;
        color: #000000;
        font-family: 'Epilogue', sans-serif;
    }
    .numberJob {
        font-size: 30px;
    }
    .clientFlex {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
    .clientLeft {
        width: 100%;
    }
    .clientRight {
        width: 100%;
    }
    .clinetContainer {
        padding-top: 5rem;
    }
    .clientLeft > p {
        margin-top: 15px;
    }
    .clientGird {
        grid-template-columns: 1fr;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .containers {
        max-width: 100%;
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .bannerContent {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        padding-left: 3rem;
        padding-right: 3rem;
    }
    .bannerLeft {
        width: 100%;
    }
    .bannerRight {
        width: 100%;
    }
    .lineImg {
        display: none;
    }
    .clientFlex {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
    .clientLeft {
        width: 100%;
    }
    .clientRight {
        width: 100%;
    }
    .clinetContainer {
        padding-top: 5rem;
    }
    .clientLeft > p {
        margin-top: 15px;
    }
    .clientGird {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .containers {
        max-width: 100%;
        padding-left: 4rem;
        padding-right: 4rem;
    }

    .bannerContent {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        padding-left: 4rem;
        padding-right: 4rem;
    }
    .bannerLeft {
        width: 100%;
    }
    .bannerRight {
        width: 100%;
    }
    .lineImg {
        display: none;
    }
    .clientFlex {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
    .clientLeft {
        width: 100%;
    }
    .clientRight {
        width: 100%;
    }
    .clinetContainer {
        padding-top: 5rem;
    }
    .clientLeft > p {
        margin-top: 15px;
    }
    .clientGird {
        grid-template-columns: 1fr 1fr;
    }
}
