.job-title{
    font-size: 42px;
}

.job-informations{
    padding: 20px
}

.job-tags {
    font-size: 12px;
    background-color: #316694;
    color: white;
    border-radius: 8px;
    display: inline-block;
    margin: 15px 15px 15px 5px;
    padding: 10px 30px;
}

.full-details-button{
    float: right !important;
    text-transform: uppercase;
    font-size: 12px;
    background-color: rgb(0, 110, 255);
    color: white;
    border-radius: 8px;
    cursor: pointer;
    padding: 0px 15px;
    max-width: 200px;
}

.full-details-button i{
    padding: 15px;
    font-size: 15px;

}

.custom-accordion{
    margin-bottom: 20px;
    color: #fff !important;
    width: 100%;
    font-size: 18px;
    /* box-shadow: 0px -2px 20px 2px rgba(196, 195, 195) !important; */
    box-shadow: var( --common-box-shadow);
    border-radius: 15px !important;
}

.details-accordion{
    background-color: #fff !important;
    width: 100% !important;
    border-radius: 15px !important;
}

.body-accordion{
    color: #000;

}

.accordion-heading{
    background-color: #2c363d !important;
    border-radius: 15px !important;
    box-shadow: var(--common-box-shadow)


}

/* .MuiSvgIcon-root{
    color: #fff;
} */

@media only screen and (max-width: 1200px) {
    .job-title{
        font-size: 30px;
    }
}

@media only screen and (max-width: 900px) {
    .job-title{
        font-size: 25px;
    }
}

@media only screen and (max-width: 600px) {
    .job-title{
        font-size: 20px;
    }
}