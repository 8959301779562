/* input[type="text"]:disabled {
    background: #fff;
    border: none;
    color: black;
    width: 100%;
}

input[type="number"]:disabled {
    background: #e0e0e0 !important;
    border: none;
    color: black;
    width: 100%;
}

input[type="date"]:disabled {
  background: rgba(212, 212, 212, 0.164) !important;
  border: none;
  width: 100%;
} */

input[type="text"] {
    color: black;
    /* border: 1px solid rgba(63, 63, 63, 0.404); */
    /* background-color: rgba(216, 216, 216, 0.233); */
    width: 100%;
}

select:disabled {
  background-color: #fff !important;
  border: none;
  color: rgb(0, 0, 0) !important;
}

.role-edit{
  font-size: 20px;
}

.table-text img{
    height: 15px;
}

.candidate-profession{
    font-size: 32px;
    margin-bottom: 25px;
}

.candidate-profile-sidebar{
    text-align: center;
    width: 20%;
  }

  .sidebar-link-text{
    text-align: left;
    width: 80%;
    cursor: pointer;
  }

  /* React Select box css for  */
  .skill-update__control{
    padding: 0px !important;
    border-radius: 0px !important;
  }
  
  .skill-update__indicator-separator{
    display: none;
  }
  
.skill-update__control--is-disabled{
    background-color: #fff !important;
    border: none !important;
}
.skill-update__single-value--is-disabled{
    color: #000 !important;
}

.skill-update__indicator{
    display: none !important;
  }

  .plus-skill{
    height: 20px;
    margin-top: 10px;
    cursor: pointer;
  }

  .hide-new-update-field{
    display: none !important;
  }

  .edit-buttons img{
    float: right;
    height: 20px;
    margin-left: 30px;
    cursor: pointer;
  }

  .edit-buttons{
    padding: 20px;
  }

.plus-big{
    display: flex;
    justify-content: center;
    align-items: center;
}

.plus-big img{
    height: 80px;
    cursor: pointer;
    justify-content: center;

}

.upload-link{
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .candidate-profession{
        font-size: 22px;
        margin-bottom: 20px;
    }
}