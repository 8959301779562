.candidate-tags {
    font-size: 12px;
    background-color: #1167B1;
    color: white;
    border-radius: 8px;
    display: inline-block;
    margin: 5px;
    padding: 5px 15px;
}

.skill-tags {
    font-size: 12px;
    background-color: #098a8a;
    color: white;
    border-radius: 8px;
    display: inline-block;
    margin: 5px;
    padding: 5px 15px;
}

.apply-button {
    padding : 10px 10px;
    font-size: 10px;
    background-color: rgb(0, 110, 255);
    color: white;
    text-transform: uppercase;
    border-radius: 5px;
    border: 1px solid rgb(0, 110, 255);
    width: 48%;
    float: left;
}

.save-button{
    padding : 10px 15px;
    font-size: 10px;
    background-color: white;
    color: rgb(0, 110, 255);
    text-transform: uppercase;
    border-radius: 8px;
    border: 1px solid rgb(0, 110, 255);
    width: 48%;
    float: right;
    cursor: pointer;
    
}

.profile-image{
    height: 100px;
    width: 100px;
    border-radius: 50% !important;
}

.wrapper {
    max-width: 400px;
  }

  .text-body {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-align: left;
    color: #000 !important;
  }

.saved-job-title{
    text-transform: capitalize;
}

.employer-title{
    color: #000;
    padding: 20px;
    font-size: 18px;
}
  @media only screen and (max-width: 600px) {
    .saved-job-title {
      font-size: 18px;
      line-height: 1;
      text-transform: capitalize;
    }

    .profile-image{
        height: 100px;
        width: 100px;
        text-align: center;
        border-radius: 50% !important;
    }

    .apply-button {
        padding : 5px 5px;
        font-size: 8px;
        background-color: rgb(0, 110, 255);
        color: white;
        text-transform: uppercase;
        border-radius: 5px;
        border: 1px solid rgb(0, 110, 255);
        width: 48%;
        float: left;
    }
    
    .save-button{
        padding : 5px 10px;
        font-size: 8px;
        background-color: white;
        color: rgb(0, 110, 255);
        text-transform: uppercase;
        border-radius: 8px;
        border: 1px solid rgb(0, 110, 255);
        width: 48%;
        float: right;
        
    }
    .candidate-tags {
        font-size: 8px;
        background-color: rgb(20, 20, 128);
        color: white;
        border-radius: 8px;
        display: inline-block;
        margin: 5px;
        padding: 10px;
    }
  }
