.testimonial-background{
    background-color: #2b3940;
}

.testimonial-image{
    padding : 100px 100px 100px 0px;
}

@media only screen and (max-width: 600px){
    .testimonial-image{
        padding : 40px;
    }
}