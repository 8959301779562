.hero-section {
    height: 650px;
    background-color: #d0efff;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* opacity: 0.6; */
    background-size: cover;
    background-blend-mode: multiply;
    
}
/* .text-area{

} */
.section-text{
    font-size: 42px;
    color: #03254C;
    line-height: normal;
    font-weight: bold;
    padding: 20px;
    height: 200px;
    display: table-cell;
    vertical-align: middle;

}

.public-section-text{
    font-size: 42px;
    color: #03254C;
    line-height: normal;
    font-weight: bold;
    padding: 20px;
    height: 450px;
    display: table-cell;
    vertical-align: middle;

}

/* Applied Candidate search Pages  */
  .background-container {
    /* background: url("/v2/images/applied-herosection.jpg"); */
    height: 100%;
    overflow: hidden;
    color: #FFFFFF;
    /* position: absolute; */
    /* height: 450px; */
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
   }
   
   .overlay {
    background: rgba(208, 239, 255, 0.8);
    overflow: hidden;
    height: 50%;
   }

.wave{
    z-index: 200;
    margin-bottom: -1px;
}

.wave img{
    margin-top: -135px;
    position: absolute;
}