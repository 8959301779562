body{
    margin-top:20px;
    background-color: #f0f2f5;
}
.dropdown-list-image {
    position: relative;
    height: 2.5rem;
    width: 2.5rem;
}
.dropdown-list-image img {
    height: 2.5rem;
    width: 2.5rem;
}
.btn-light {
    color: #2cdd9b;
    background-color: #e5f7f0;
    border-color: #d8f7eb;
}

.dropdown-item{
    cursor: pointer;
}

.hover-class {
    cursor: pointer;
    transition: 0.3s;
}

.hover-class:hover {
    background-color: #dcf9f581;
}



