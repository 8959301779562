.profile-percent-box{
    background-color: #2B3940;
    color: #fff;
    padding: 5px 15px;
    border-radius: 3px;
    font-family: 'proxima-nova', sans-serif;
    font-size: 12px;
    font-weight: 800;
    margin-top: -10px;
}

.dashboard-icon{
    height: 20px;
    width: 20px;
    margin-left: 20px;
}

.dashboard-menu-item{
    font-size: 20px;
    font-weight: 300;
    padding-left: 20px;
}

.candidate-name{
    margin-top: 20px;
    font-size: 34px;
    font-weight: 900;
}

.profession-title{
    margin-top: 10px;
    font-size: 22px;
    font-weight: 400;
    color: #000;
}

.sidebarActiveTab  .dashboard-menu-item{
    margin-left: 15px;
    font-weight: 600;
    color: #000;
}