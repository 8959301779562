.heading-name{
    font-size: 40px;
}

.info-icon{
    height: 60px;
    width: 60px;
}

.info-title{
    font-size: 20px;
    margin: auto;
    padding-top: 20px;
}

.profile-steps{
    display: block;
}

.tab-headings{
    /* display: inline-block; */
    font-size: 24px;
    font-weight: 700;
    color: black;
    display: flex;
    justify-content: center;
}

.tab-title{
    margin: auto;
}

.start-end{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.start-end img{
    height: 50px;
    width: 70px;
}

.sub-steps{
    margin-left: 50px;
}

.sub-steps > div{
    padding: 10px;
}

.steps img{
    height: 20px;
}

.career-image{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.career-image span{
    font-weight: 900;
    text-align: center;
    line-height: 2;
    color: #000;
    margin-top: 20px;
}

.career-image img{
    max-height: 300px;
}

.main-step{
    padding: 10px 0px;
}


.shadow-line{
    /* background: #f3f3f3; */
    border-left: 15px solid #f3f3f3;
}

.main-step span{
    color: #000;
    margin-left: 10px;
}

.main-step span:hover{
    color: rgb(0, 110, 255) !important;
    margin-left: 20px;
}