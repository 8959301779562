.applied-job-heading{
    color: #1167B1;
    text-align: left !important;
}

.table-row{
    border-top: 1px solid #00000010;
    color: #000;
}

.details-button{
    background-color: #2a9bf4;
    border-radius: 3px;
    padding: 5px 10px;
    color: #fff;
    border: none;
    font-size: 12px;
    font-weight: 700;
}
td{
    text-align: left;
}

tr{
    text-align: left;
}