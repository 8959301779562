.updateModal {}

.updateModal label {
    color: #424447 !important;
    font-size: 14px;
    font-weight: 500;
    font-family: Epilogue;
    margin-bottom: 8px !important;
}

.updateModal input[type=text]:not([id^="react-select"]) {
    outline: 1px solid #e1e6eb !important;
    border-radius: 5px !important;
}

.updateModal input[type=date] {
    outline: 1px solid #e1e6eb !important;
    border-radius: 5px !important;

}

.updateModal input[type=number] {
    outline: 1px solid #e1e6eb !important;
    border-radius: 5px !important;

}

.heading_black {
    font-size: 16px;
    font-weight: 600;
    font-family: Epilogue;
    color: #1D1E1B;
    margin-right: 10px;
}

.heading_blue {
    font-size: 16px;
    font-weight: 700;
    font-family: Epilogue;
    color: #0275D8;
}

.infoBlock {
    display: flex;
    align-items: center;
    min-width: 170px;
    margin-bottom: 6px;
}

.cardHeader {
    padding: 10px 20px;
    border-bottom: 1px solid rgb(204, 204, 204);
}

.sectionHeader {
    font-size: 18px;
    font-weight: 700;
    font-family: Epilogue;
    color: #424447;
}

.editButton {
    display: flex !important;
    justify-content: space-around;
    align-items: center;
    color: #0275D8;
    font-family: 'Epilogue';
    font-weight: 600 !important;
    font-size: 16px !important;
    background-color: #E5F5FF;
    padding: 2px 20px !important;
}

.personalInfoBlock {
    display: flex;
    font-family: 'Poppins';
    font-size: 16px;
    color: #1D1E1B;
    margin-bottom: 5px;
}

.personalInfoTitle {
    display: flex;
    justify-content: space-between;
    width: 170px;
}

.personalInfoTitleSmallDevice {
    font-family: 'Poppins';
    font-size: 16px;
    color: #1D1E1B;
    font-weight: 600;
}

.personalInfoValue {
    margin-left: 30px;
}

.personalInfoValueSmallDevice{
    font-family: 'Poppins';
    font-size: 16px;
    color: #424447;
}

.careerInfoBlock {
    display: flex;
    font-family: 'Poppins';
    font-size: 16px;
    color: #1D1E1B;
    margin-bottom: 5px;
}

.careerInfoTitle {
    display: flex;
    justify-content: space-between;
    min-width: 200px;
    width: 200px;
}

.careerInfoValue {
    margin-left: 30px;
}

.careerInfoTitleSmallDevice {
    font-family: 'Poppins';
    font-size: 16px;
    color: #1D1E1B;
    font-weight: 600;
}
.careerInfoValueSmallDevice{
    font-family: 'Poppins';
    font-size: 16px;
    color: #424447;
}

.addButton {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.addButtonTitle {
    display: flex;
    margin-left: 10px;
    font-size: 16px;
    font-weight: 600;
    font-family: Epilogue;
    color: #0079C1;
}

.inputTitle {
    font-size: 14px;
    font-weight: 600;
    font-family: Epilogue;
    color: #1D1E1B;
}

.platformSelect {
    height: 44px !important;
    border-radius: 5px !important;
}

.platformSelect:disabled {
    border: 1px solid rgb(214, 214, 214) !important;
    background-color: rgb(226, 226, 226) !important;
}

.singleSocialProfile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
}

.singleSocialProfilePlatform {
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 600;
    color: #424447;

}

.singleSocialProfileLink {
    font-family: 'Poppins';
    font-size: 16px;
    max-width: Calc(100% - 150px);
}

.singleSocialProfilePlatformContainer {
    min-width: 150px;
}

.singleSocialProfileContent {
    display: flex;
    max-width: Calc(100% - 85px);
}

.actionContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 80px;
}

.validationErrorMessage {
    color: red;
    text-align: center;
    text-align: -webkit-center;
    margin: 10px;
}

/* Key Skill */
.skillSkeletonContainer {
    display: flex;
}

.skillSkeletonContainer>* {
    margin: 5px;
}

.skillContainer {
    display: flex;
    flex-wrap: wrap;
}

.skillContainer>* {
    margin: 5px;
}

.singleSkill {
    display: flex;
    border: 1px solid #0079C1;
    padding: 5px 10px 5px 5px;
    align-items: center;
    border-radius: 5px;
    font-family: Poppins;
    font-weight: 500;
    font-size: 16;
    color: #0079C1;
}

/* education */

.singleEducation {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: flex-start;
}

.eduDegree {
    font-family: Epilogue;
    font-size: 18px;
    font-weight: bold;
    color: #0079C1;
    margin-bottom: 10px;
}

.eduInstitute {
    font-family: Epilogue;
    font-size: 16px;
    font-weight: 600;
    color: #424447;
    margin-bottom: 10px;
}

.eduResult {
    font-family: Poppins;
    font-size: 14px;
    color: #424447;
    margin-bottom: 10px;
}

.eduDetails {
    font-family: Poppins;
    font-size: 14px;
    color: #72737C;
}

/* Project */

.singleProject {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: flex-start;
}

.projectTitle {
    font-family: Epilogue;
    font-size: 18px;
    font-weight: bold;
    color: #0079C1;
    margin-bottom: 10px;
}

.projectDuration {
    font-family: Poppins;
    font-size: 16px;
    color: #424447;
    margin-bottom: 10px;
}

.projectCompany {
    font-family: Poppins;
    font-size: 14px;
    color: #424447;
    margin-bottom: 10px;
}

.projectDesc {
    font-family: Poppins;
    font-size: 14px;
    color: #72737C;
}

/* Certificate */

.singleCertificate {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: flex-start;
}

.certificateTitle {
    font-family: Epilogue;
    font-size: 18px;
    font-weight: bold;
    color: #0079C1;
    margin-bottom: 10px;
}

.certificateInstitute {
    font-family: Poppins;
    font-weight: 600;
    font-size: 16px;
    color: #424447;
    margin-bottom: 10px;
}

.certificateDuration {
    font-family: Poppins;
    font-size: 16px;
    color: #424447;
    margin-bottom: 10px;
}

.certificateDesc {
    font-family: Poppins;
    font-size: 14px;
    color: #72737C;
}

/* exp */

.singleExp {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: flex-start;
}

.expTitle {
    font-family: Epilogue;
    font-size: 18px;
    font-weight: bold;
    color: #0079C1;
    margin-bottom: 10px;
}

.expOrg {
    font-family: Epilogue;
    font-size: 16px;
    font-weight: 600;
    color: #1D1E1B;
    margin-bottom: 10px;
    display: flex;
}

.expDateDuration {
    font-family: Poppins;
    font-size: 16px;
    color: #1D1E1B;
    margin-bottom: 10px;
}

.expDesc {
    font-family: Poppins;
    font-size: 14px;
    color: #72737C;
}

/* resume */

.resumeTitle {
    font-family: Epilogue;
    font-size: 18px;
    font-weight: bold;
    color: #0079C1;
    margin-bottom: 10px;
}

.resumeHint {
    font-family: Poppins;
    font-size: 14px;
    color: #72737C;
}

.resumeUploadedOn {
    font-family: Poppins;
    font-size: 14px;
    color: #72737C;
}

/* hobby */

.singleHobby {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: flex-start;
}

.hobbyTitle {
    font-family: Epilogue;
    font-size: 18px;
    font-weight: bold;
    color: #0079C1;
    margin-bottom: 10px;
}
.hobbyDetails {
    font-family: Poppins;
    font-size: 14px;
    color: #72737C;
}

/* reference */

.singleRef {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: flex-start;
}

.refTitle {
    font-family: Epilogue;
    font-size: 18px;
    font-weight: bold;
    color: #0079C1;
    margin-bottom: 10px;
}

.refDesignation{
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    color: #424447;
    
}

.refMobileNo{
    font-family: Poppins;
    font-size: 14px;
    color: #424447;
    margin-top: 10px;
}

.follwInfoSection{
    display: flex;
    justify-content: space-around;
    font-size: 14px;
    font-family: Poppins;
    font-weight: 600;
    margin-top: 5px;
    color: #0079C1;
}