.resume-breadcrumb{
    /* display: inline-block; */
    text-align: left !important;
    padding-left: 20px;
    font-size: 20px;
}
.prev-url{
    color: rgb(105, 105, 105);
}

.crnt-url{
    color: #1167B1;
    text-align: left !important;
}