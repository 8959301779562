@import url('https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100..900;1,100..900&display=swap');
/*================== 
theme color 
==================*/
:root {
    --primary-color: #6966ff;
    --title-color: #000000;
    --text-color-1: #282828;
    --text-color-2: #ffffff;
}

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

ul,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
p,
address {
    margin: 0;
    padding: 0;
}

/* li {
    list-style: none;
} */
a {
    text-decoration: none;
}
.containers {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Epilogue', sans-serif;
}

/* banner css start */
.bannerContainer {
    width: 100%;
    background-image: url('../../images/lp2/banner/bg-banner.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.bannerContent {
    width: 700px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-top: 6rem;
    padding-bottom: 6rem;
}
.activeContent {
    background-color: #f7f9fb;
    border-radius: 18px;
    padding: 15px 30px;
}
.vacancieNumber {
    color: var(--primary-color);
    font-size: 22px;
    font-weight: 800;
}
.vacancieText {
    font-size: 16px;
    font-weight: 700;
    color: #000000;
}
.titleText {
    font-size: 42px;
    font-weight: 700;
    line-height: 52px;
    font-family: 'Epilogue', sans-serif;
}
.titleTextColor {
    color: var(--primary-color);
}
.bannerBtnWrpper {
    margin-top: 2.5rem;
    margin-bottom: 2rem;
}
.bannerIcon {
    max-width: 30px;
}
.bannerIcon > img {
    max-width: 100%;
    object-fit: cover;
}
.announcementText {
    font-size: 16px;
    color: var(--title-color);
    font-weight: 600;
}
.btnsLink {
    background-color: var(--primary-color);
    color: var(--text-color-2) !important;
    border: none;
    border-radius: 40px;
    padding: 15px 25px;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    text-decoration: none;
}
/* banner css end */

/* slider css start */
.sliderContainer {
    margin-bottom: 6rem;
    border-top: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
    overflow: hidden;
    position: relative;
}
.sliderWrapper {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 2rem;
    margin-top: 15px;
    margin-bottom: 15px;
    transition: transform 0.5s ease-in-out;
    will-change: transform;
}

.sliderImg {
    width: 150px;
}
.sliderImg > img {
    width: 100%;
}
.sliderBtn {
    background-color: #f7f9fb;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    border: 0;
    color: #2f2f2f;
    cursor: pointer;
}

.leftBtn {
    position: absolute;
    top: 50%;
    left: 0px;
    z-index: 2;
    transform: translateY(-50%);
}
.rightBtn {
    position: absolute;
    top: 50%;
    right: 0px;
    z-index: 2;
    transform: translateY(-50%);
}
.sliderBtn:hover,
.activeBtn {
    background-color: var(--primary-color);
    color: #ffffff;
}
/* slider css end */

/* metting css start */
.mettingContainer {
    display: flex;
    align-items: center;
    gap: 3rem;
}
.mettingLeft {
    width: 50%;
}
.mettingRight {
    width: 50%;
}
.mettingContnet {
    color: var(--text-color-1);
    padding-top: 1rem;
    padding-bottom: 2rem;
}
.contentText {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
}
/* metting css end */

/* client review css start */
.clinetContainer {
    background-image: url('../../images/lp2/client/Shadow.png');
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;
    padding-top: 6rem;
    padding-bottom: 6rem;
}
.clientGird {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    margin-top: 4rem;
}
.clientCard {
    display: flex;
    gap: 15px;
}
.clientImg {
    height: 100px;
    width: 100px;
    border-radius: 50%;
}
.clientImg > img {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
}
.cloneIcon {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 8px;
}
.clientName {
    font-size: 18px;
    font-weight: 700;
    color: var(--text-color-1);
    margin-bottom: 8px;
}
.clientDig {
    margin-bottom: 8px;
    font-size: 16px;
    color: var(--text-color-1);
    font-weight: 400;
}
.clientStart {
    margin-bottom: 18px;
}
.clientStart > i {
    color: #ffa722;
    font-size: 14px;
}
.clientText {
    color: var(--text-color-1);
    margin-right: 30px;
}
/* client review css end */

/* job css start */
.jobContainer {
    background-image: url('../../images/lp2/jobs/bg-img.png');
    background-repeat: no-repeat;
    margin-bottom: 6rem;
    border-radius: 15px;
    height: 300px;
    display: flex;
    align-items: center;
}
.jobWrapper {
    display: flex;
    align-items: center;
    padding: 2rem 4rem;
    gap: 3rem;
    position: relative;
}
.jobLeft {
    width: 600px;
}
.jobRight {
    width: 540px;
    height: 100%;
}
.jobImg {
    width: 100%;
    height: 377px;
    position: absolute;
    top: -91px;
    right: -130px;
}

.jobImg > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.jobBtn {
    margin-top: 20px;
    margin-bottom: 30px;
    cursor: pointer;
}
.jobBtn > a {
    display: inline-block;
    background-color: var(--text-color-2);
    color: var(--title-color) !important;
    border: none;
    border-radius: 40px;
    padding: 15px 25px;
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
}
.textJob {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--text-color-2);
}
.textJob > span {
    font-size: 14px;
    font-weight: 600;
}
.jobTextContent {
    display: flex;
    align-items: center;
    gap: 15px;
}
/* job css end */

/* footer added */
.boxs {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 90px;
    background-color: #f6f6f6;
    border-radius: 0;
    z-index: 100000;
    color: white;
    transition: bottom 0.5s ease-in-out;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.buttons {
    background: #ffa900;
    text-transform: capitalize;
    font-family: 'Epilogue', sans-serif;
    font-weight: 700;
    font-size: 20px;
    max-width: 500px;
    padding: 16px 48px;
    border: 0;
    border-radius: 40px;
    cursor: pointer;
}

/* small device  */

@media only screen and (min-width: 250px) and (max-width: 575px) {
    .containers {
        max-width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .bannerContent {
        width: 100%;
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
    .sliderBtn {
        background-color: #f7f9fb;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        border: 0;
        color: #2f2f2f;
    }

    .titleText {
        font-size: 28px;
        line-height: 38px;
    }
    .activeContent {
        background-color: #f7f9fb;
        border-radius: 18px;
        padding: 10px 15px;
    }
    .vacancieNumber {
        font-size: 14px;
    }
    .vacancieText {
        font-size: 12px;
    }
    .announcementText {
        font-size: 14px;
    }
    .bannerBtnWrpper {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    .mettingContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 3rem;
    }
    .mettingRight {
        width: 100%;
    }
    .mettingLeft {
        width: 100%;
    }
    .sliderContainer {
        margin-bottom: 3rem;
    }
    .btnsLink {
        font-size: 14px;
    }
    .clientGird {
        grid-template-columns: 1fr;
    }
    .clientText {
        margin-right: 0px;
    }
    .clinetContainer {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
    .jobWrapper {
        display: flex;
        flex-direction: column-reverse;
        justify-content: start;
        gap: 2rem;
    }
    .jobLeft {
        width: 100%;
    }
    .jobRight {
        width: 100%;
        height: 100%;
        position: relative;
    }
    .jobTextContent {
        display: flex;
        flex-direction: column;
        align-items: start;
    }
    .jobContainer {
        height: 100%;
        margin-bottom: 6rem;
    }
    .jobImg {
        display: none;
    }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
    .containers {
        max-width: 100%;
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .bannerContent {
        width: 100%;
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
    .titleText {
        font-size: 30px;
        line-height: 44px;
    }
    .vacancieNumber {
        font-size: 16px;
    }
    .vacancieText {
        font-size: 14px;
    }
    .mettingContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 3rem;
    }
    .mettingRight {
        width: 100%;
    }
    .mettingLeft {
        width: 100%;
    }
    .sliderContainer {
        margin-bottom: 4rem;
    }
    .clientGird {
        grid-template-columns: 1fr;
    }
    .clientText {
        margin-right: 0px;
    }
    .clinetContainer {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
    .jobWrapper {
        display: flex;
        flex-direction: column-reverse;
        justify-content: start;
        gap: 2rem;
    }
    .jobLeft {
        width: 100%;
    }
    .jobRight {
        width: 100%;
        position: relative;
    }
    .jobTextContent {
        display: flex;
        flex-direction: column;
        align-items: start;
    }
    .jobContainer {
        height: 100%;
        margin-bottom: 8rem;
    }
    .jobImg {
        display: none;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .containers {
        max-width: 100%;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .titleText {
        font-size: 38px;
        line-height: 48px;
    }
    .clientText {
        margin-right: 0px;
    }
    .jobContainer {
        height: 100%;
    }
    .jobWrapper {
        display: flex;
        flex-direction: column-reverse;
        gap: 2rem;
    }
    .jobLeft {
        width: 100%;
        position: relative;
    }
    .jobRight {
        width: 100%;
    }

    .jobImg {
        display: none;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .containers {
        max-width: 100%;
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .jobImg {
        width: 100%;
        height: 378px;
        position: absolute;
        top: -92px;
        right: 80px;
    }
}
