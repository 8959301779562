#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -20rem;
  transition: margin 0.25s ease-out;
}


#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 20rem;
}

#page-content-wrapper {
  min-width: 100vw;
}

#search-job.sb-sidenav-toggled #wrapper #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #search-job.sb-sidenav-toggled #wrapper #sidebar-wrapper {
    margin-left: -20rem;
  }
}

.mobile-view {
  width: 300px;
  background-color: #ffffff;
  padding: 20px;
}

@media only screen and (min-width: 769px) {
  .open-filter-drawer{
    display: none;
  }
}
.open-filter-drawer{
  /* display: none; */
  background-color: #3571f5;
  color: #ffffff;
  border: none;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}

@media only screen and (max-width: 768px ){
  .open-filter-drawer{
    display: block;
    width: 100%;
  padding : 10px 10px;
  font-size: 10px;
  background-color: rgb(0, 110, 255);
  color: white;
  text-transform: uppercase;
  border-radius: 8px;
  border: 1px solid rgb(0, 110, 255);
  float: left;
}
}

@media only screen and (max-width: 768px) {
  .job-search-sidebar-search-job{
    display: none;
  }
  
}

.job-search-sidebar-search-job{
  top: 100px;
  position: sticky;
  overflow-y: scroll;
  /* background-color: #fbfeff; */
  padding: 20px;
  height: calc(100vh - 105px);
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.filte-sidebar-heading{
  display: flex;
  padding: 20px 0px;
  flex-flow: column wrap;
  height: 70px;
}

.filte-sidebar-heading span{
  font-size: 25px;
  font-weight: 700;
  color: black;
  padding: 0px 0px;
  margin-right: 20px;
}

.clear-filter-sm{
  padding: 0px;
  border-radius: 3px;
  border: 1px solid #2B3940;
  padding: 5px 10px;
  background-color: #2B3940;
  color: white !important;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;

}

.custom-design{
  padding: 10px !important;
  border-radius: 5px !important;
  /* box-shadow: 0px -2px 20px 2px rgb(53 113 245 / 45%) !important; */
}

.react-select__control{
  padding: 3px !important;
  border-radius: 5px !important;
  /* box-shadow: 0px -2px 20px 2px rgb(53 113 245 / 45%) !important; */
}

.react-select__indicator-separator{
  display: none;
}

.react-select__indicator{
  color: #88898b !important;
}

.searchButton{
  width: 100%;
  background-color: #3571f5;
  text-transform: uppercase;
  padding: 15px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  /* box-shadow: 0px -2px 20px 2px rgb(53 113 245 / 45%) !important; */
}

.clearButton{
  width: 100%;
  background-color: #2B3940;
  text-transform: uppercase;
  padding: 15px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px -2px 20px 2px rgb(53 113 245 / 45%) !important;
}

.range-box{
  background-color: #ffffff;
  padding: 15px;
  border-radius: 5px !important;
  /* box-shadow: 0px -2px 20px 2px rgb(53 113 245 / 45%) !important; */
}

.applied-card-icons{
  height: 35px !important;
  cursor: pointer;
}

.number-of-result{
  font-weight: 800;
  color: #03254C;
}

.number-of-result span{
  font-size: 20px;
}

label{
  margin: 0px !important;
}

.job-search-sidebar{
  background-color: #d0efff;
  height: 100%;
  /* margin-top: 40px; */
  padding: 20px;
}
