@import url('https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100..900;1,100..900&display=swap');
/*================== 
theme color 
==================*/
:root {
    --primary-color: #3b5997;
    --secondary-color: #f0a933;
    --title-color: #000000;
    --text-color-1: #1e1e1e;
    --text-color-2: #fcfcfd;
}

.containers {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Epilogue', sans-serif;
}

/* banner css start */
.bannerContainer {
    width: 100%;
    background-image: url('../../images/lp4/Bg/bg.png');
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.bannerFlex {
    display: flex;
    align-items: center;
    gap: 3rem;
}
.bannerFlexLeft {
    width: 40%;
}
.bannerFlexRight {
    width: 60%;
}

.titleText {
    font-size: 36px;
    font-weight: 700;
    line-height: 50px;
    font-family: 'Epilogue', sans-serif;
    color: var(--primary-color);
    line-height: 48px;
}
.titleTextColor {
    color: var(--primary-color);
}
.activeContent {
    color: var(--secondary-color);
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 2rem;
}
.contentText {
    font-size: 16px;
    font-weight: 600;
    color: var(--text-color-1);
    line-height: 26px;
}
.btnsLink {
    background-color: var(--primary-color);
    color: var(--text-color-2) !important;
    border: none;
    border-radius: 40px;
    padding: 15px 40px;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
}
.btnsLink > img {
    max-width: 20px;
}
.btnPadding {
    padding-top: 2rem;
}
.bannerImage {
    height: 100%;
    width: 100%;
}
/* banner css end */

/* slider css start */
.sliderGrid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 1rem;
    padding-bottom: 6rem;
}
.sliderCard {
    background-color: #ffffff;
    padding: 10px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sliderCard > img {
    max-width: 100px;
    max-height: 50px;
    object-fit: cover;
}
.viewLink {
    color: #232233;
    text-decoration: underline;
}
.titleColor {
    color: var(--text-color-1);
}
/* slider css end */

/* job css start */
.findJobContianer {
    padding-bottom: 6rem;
}
.jobGird {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin-top: 3rem;
}
.jobCard {
    background-color: #fcfcfd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.jobCard > div {
    border: 1px solid #e0e0e0;
    border-radius: 5px;
}
.jobHeaderContent {
    padding: 15px;
}
.jobHeaderContent > h3 {
    font-size: 18px;
    color: #007bff;
    font-weight: 600;
    line-height: 22px;
}
.jobContent {
    display: flex;
    align-items: flex-start;
    gap: 15px;
}
.jobContentLeft {
    width: 80%;
}
.jobImg {
    width: 20%;
}
.jobImg > img {
    width: 100%;
    object-fit: cover;
    margin-left: auto;
}
.jobContentLeft > h5 {
    font-size: 16px;
    color: #222222;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 6px;
}
.jobContentLeft > p {
    font-size: 14px;
    color: var(--text-color-1);
    line-height: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 700;
}
.jobCardFooter {
    display: flex;
    align-items: center;
    padding: 15px;
    gap: 15px;
    border-top: 1px solid #e0e0e0;
}
.jobFooterLeft {
    width: 60%;
    display: flex;
    align-items: center;
    column-gap: 10px;
}
.jobFooterRight {
    width: 40%;
}
.viewDetials {
    background-color: #007bff;
    color: var(--text-color-2);
    font-size: 12px;
    font-weight: 600;
    border-radius: 6px;
    padding: 12px 20px;
    width: 100%;
}
.dateImg {
    background-color: #007bff1f;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}
.dateImg > img {
    max-width: 25px;
}
.deadline > h6 {
    font-size: 12px;
    font-weight: 700;
    margin: 1px 0px;
    padding: 1px 0px;
    color: var(--text-color-1);
    height: 18px;
}
.deadline > span {
    font-size: 12px;
    font-weight: 700;
    color: var(--text-color-1);
}
/* job css end */

/* user css start */
.userContainer {
    width: 100%;
    background-image: url('../../images/lp4/Bg/user_img.png');
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    padding-bottom: 6rem;
    padding-top: 6rem;
}
.userWidth {
    width: 50%;
    margin: 0 auto;
}

.clientReviewCard {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
}
.clientReviewCard > div {
    height: 70px;
    width: 70px;
    margin: 0 auto 10px auto;
    border-radius: 50%;
}
.clientReviewCard > div > img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
}
.clientReviewCard > h4 {
    font-size: 18px;
    color: var(--title-color);
    font-weight: 600;
    line-height: 24px;
    margin: 0px;
    text-align: center;
    padding: 0px;
}
.clientReviewCard > h6 {
    font-size: 14px;
    color: var(--text-color-1);
    margin: 0px;
    padding: 0px;
    text-align: center;
}
.clientReviewCard > p {
    font-size: 16px;
    line-height: 24px;
    margin-top: 15px;
    text-align: center;
}

/* Slider.module.css */
.testimonialsFlex {
    display: flex;
    flex-wrap: nowrap;
    transition: transform 0.5s ease;
}
.testimonialsGrid {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
}
.sliderPagination {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
}

.paginationDot {
    display: inline-block;
    width: 10px;
    height: 10px;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.3s;
}

.paginationDot.active {
    background: #000;
}

/* user css end */
/* footer added with floating */
.boxs {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 90px;
    background-color: #3b5997;
    border-radius: 0;
    z-index: 100000;
    color: white;
    transition: bottom 0.5s ease-in-out;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.buttons {
    background: #ffa900;
    text-transform: capitalize;
    font-family: 'Epilogue', sans-serif;
    font-weight: 700;
    font-size: 20px;
    max-width: 500px;
    padding: 16px 48px;
    border: 0;
    border-radius: 40px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s ease-out;
}

.buttons:hover {
    background-color: #ff9900;
    transform: scale(1.05);
}

/* small device  */

@media only screen and (min-width: 250px) and (max-width: 575px) {
    .bannerFlex {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 3rem;
    }
    .bannerFlexLeft {
        width: 100%;
    }
    .bannerFlexRight {
        width: 100%;
    }
    .containers {
        max-width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .activeContent {
        text-align: center;
    }
    .textCenters {
        text-align: center;
    }
    .btnPadding {
        display: flex;
        justify-content: center;
    }
    .sliderGrid {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .jobGird {
        grid-template-columns: 1fr;
    }
    .userWidth {
        width: 100%;
    }
    .clientReviewCard {
        width: 100%;
    }
    .testimonialsGrid {
        width: 100%;
    }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
    .bannerFlex {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 2rem;
    }
    .bannerFlexLeft {
        width: 100%;
    }
    .bannerFlexRight {
        width: 100%;
    }
    .containers {
        max-width: 100%;
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .activeContent {
        text-align: center;
    }
    .textCenters {
        text-align: center;
    }
    .btnPadding {
        display: flex;
        justify-content: center;
    }
    .sliderGrid {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .jobGird {
        grid-template-columns: 1fr;
    }
    .userWidth {
        width: 100%;
    }
    .clientReviewCard {
        width: 100%;
    }
    .testimonialsGrid {
        width: 100%;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .containers {
        max-width: 100%;
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .activeContent {
        text-align: center;
    }
    .bannerFlex {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 2rem;
    }
    .bannerFlexLeft {
        width: 100%;
    }
    .bannerFlexRight {
        width: 100%;
    }
    .textCenters {
        text-align: center;
    }
    .btnPadding {
        display: flex;
        justify-content: center;
    }
    .sliderGrid {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .jobGird {
        grid-template-columns: 1fr 1fr;
    }
    .userWidth {
        width: 100%;
    }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .containers {
        max-width: 100%;
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .jobGird {
        grid-template-columns: 1fr 1fr;
    }
}
/* FindJobSection specific styles */
.findJobContianer {
    padding-bottom: 6rem;
}

.jobGird {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Adjust column width as per your design */
    gap: 1rem;
    margin-top: 3rem;
}

.jobCard {
    background-color: #fcfcfd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.jobImg {
    width: 100%;
}

.jobImg img {
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
}

/* Media queries */
@media only screen and (max-width: 575px) {
    .jobGird {
        grid-template-columns: 1fr; /* Display cards vertically on smaller devices */
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .jobGird {
        grid-template-columns: 1fr 1fr; /* Two columns on tablets */
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .jobGird {
        grid-template-columns: 1fr 1fr; /* Two columns on smaller desktops */
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .jobGird {
        grid-template-columns: 1fr 1fr; /* Two columns on larger desktops */
    }
}

/* End of FindJobSection specific styles */