:root {
    --app-font-xs: 12px;
    --app-font-sm: 14px;
    --app-font-md: 16px;
    --app-font-lg: 18px;
    --app-font-xl: 20px;
    --app-font-2xl: 24px;
  
    --app-font-weight-normal: 400;
    --app-font-weight-semi: 500;
    --app-font-weight-medium: 600;
    --app-font-weight-bold: 700;

    --font-primary: Epilogue ;
    --font-secondary: Poppins ;
  }

body {
  --app-appbar-bg-color: white;
  --app-appbar-text-color: black;

  --app-bg-color-primary: #00457c;
  --app-bg-color-sky: #0079c1;
  --app-bg-color-light-gray: #f3f3f3;
  --app-bg-color-off-white: #f9f9f9;
  --app-bg-color-danger: #db1616;
  --app-bg-color-success: #15a449;
  --app-bg-color-off-white: #f9f9f9;

  --app-text-color-primary: #0079c1;
  --app-text-color-secondary: #424447;
  --app-text-color-tertiary: #72737c;
  --app-text-color-danger: #db1616;
  --app-text-color-light: white;

  --app-text-chip-color-danger: #db1616;
  --app-text-chip-color-success: #15a449;
  --app-text-chip-color-gray: #72737c;
  --app-text-chip-color-yellow: #ed7200;
  --app-text-chip-color-purple: #8743df;

  --app-menu-highlight-color-bg: #eff7ff;
  --app-menu-border-color: #0079c1;
  --app-border-color-primary: #c8eaff;

  --app-button-bg-color-primary: #0079c1;
  --app-button-bg-color-light-primary: #e5f5ff;
  --app-button-bg-color-secondary: white;

  --app-action-bg-color-primary: #e5f5ff;
  --app-action-bg-color-lightgray: #80808014;

  --app-table-header-bg-secondary: #e5f5ff;

  --app-unread-bg-color: #f2f6fc;

  --app-chip-bg-color-success: #ebfff2;
  --app-chip-bg-color-danger: #ffecec;
  --app-chip-bg-color-gray: #f4f4f4;
  --app-chip-bg-color-yellow: #fbf1e7;
  --app-chip-bg-color-purple: #f2e9ff;
  --app-chip-bg-color-primary: #e5f5ff;
}

.app-appbar {
    background-color: var(--app-appbar-bg-color) !important ;
    color: var(--app-appbar-text-color) !important ;
  }
  
  /* heading */
  .app-heading-primary-md {
    color: var(--app-text-color-primary) !important ;
    font-family: var(--font-primary) !important ;
    font-size: var(--app-font-md) !important ;
    font-weight: var(--app-font-weight-bold) !important ;
  }

  .app-heading-primary-sm {
    color: var(--app-text-color-primary) !important ;
    font-family: var(--font-primary) !important ;
    font-size: var(--app-font-sm) !important ;
    font-weight: var(--app-font-weight-bold) !important ;
  }
  
  .app-heading-primary-lg {
    color: var(--app-text-color-primary) !important ;
    font-family: var(--font-primary) !important ;
    font-size: var(--app-font-lg) !important ;
    font-weight: var(--app-font-weight-bold) !important ;
  }
  
  .app-heading-primary-xl {
    color: var(--app-text-color-primary) !important ;
    font-family: var(--font-primary) !important ;
    font-size: var(--app-font-xl) !important ;
    font-weight: var(--app-font-weight-bold) !important ;
  }
  
  .app-heading-primary-2xl {
    color: var(--app-text-color-primary) !important ;
    font-family: var(--font-primary) !important ;
    font-size: var(--app-font-2xl) !important ;
    font-weight: var(--app-font-weight-bold) !important ;
  }
  
  .app-heading-medium-primary-sm {
    color: var(--app-text-color-primary) !important ;
    font-family: var(--font-primary) !important ;
    font-size: var(--app-font-sm) !important ;
    font-weight: var(--app-font-weight-medium) !important ;
  }
  
  .app-heading-medium-sp-sm {
    color: var(--app-text-color-secondary) !important ;
    font-family: var(--font-primary) !important ;
    font-size: var(--app-font-sm) !important ;
    font-weight: var(--app-font-weight-medium) !important ;
  }
  
  .app-heading-white-md {
    color: white;
    font-family: var(--font-primary) !important ;
    font-size: var(--app-font-md) !important ;
    font-weight: var(--app-font-weight-medium) !important ;
  }
  
  .app-heading-white-sm {
    color: white;
    font-family: var(--font-primary) !important ;
    font-size: var(--app-font-sm) !important ;
    font-weight: var(--app-font-weight-medium) !important ;
  }
  
  .app-heading-semi-wp-md {
    color: white;
    font-family: var(--font-primary) !important ;
    font-size: var(--app-font-md) !important ;
    font-weight: var(--app-font-weight-semi) !important ;
  }
  
  .app-heading-secondary-md {
    color: var(--app-text-color-secondary) !important ;
    font-family: var(--font-secondary) !important ;
    font-size: var(--app-font-md) !important ;
    font-weight: var(--app-font-weight-bold) !important ;
  }
  
  .app-heading-secondary-sm {
    color: var(--app-text-color-secondary) !important ;
    font-family: var(--font-secondary) !important ;
    font-size: var(--app-font-sm) !important ;
    font-weight: var(--app-font-weight-bold) !important ;
  }
  
  .app-heading-sp-md {
    color: var(--app-text-color-secondary) !important ;
    font-family: var(--font-primary) !important ;
    font-size: var(--app-font-md) !important ;
    font-weight: var(--app-font-weight-bold) !important ;
  }
  
  .app-heading-semi-sp-xl {
    color: var(--app-text-color-secondary) !important ;
    font-family: var(--font-primary) !important ;
    font-size: var(--app-font-xl) !important ;
    font-weight: var(--app-font-weight-semi) !important ;
  }
  
  .app-heading-primary-2xl {
    color: var(--app-text-color-primary) !important ;
    font-family: var(--font-primary) !important ;
    font-size: var(--app-font-2xl) !important ;
    font-weight: var(--app-font-weight-bold) !important ;
  }
  
  .app-heading-sp-2xl {
    color: var(--app-text-color-secondary) !important ;
    font-family: var(--font-primary) !important ;
    font-size: var(--app-font-2xl) !important ;
    font-weight: var(--app-font-weight-bold) !important ;
  }
  
  .app-heading-sp-sm {
    color: var(--app-text-color-secondary) !important ;
    font-family: var(--font-primary) !important ;
    font-size: var(--app-font-sm) !important ;
    font-weight: var(--app-font-weight-bold) !important ;
  }
  
  .app-heading-medium-sp-sm {
    color: var(--app-text-color-secondary) !important ;
    font-family: var(--font-primary) !important ;
    font-size: var(--app-font-sm) !important ;
    font-weight: var(--app-font-weight-medium) !important ;
  }
  
  .app-heading-medium-sp-md {
    color: var(--app-text-color-secondary) !important ;
    font-family: var(--font-primary) !important ;
    font-size: var(--app-font-md) !important ;
    font-weight: var(--app-font-weight-medium) !important ;
  }
  
  .app-heading-ps-sm {
    color: var(--app-text-color-primary) !important ;
    font-family: var(--font-secondary) !important ;
    font-size: var(--app-font-sm) !important ;
    font-weight: var(--app-font-weight-bold) !important ;
  }
  
  .app-heading-semi-ps-sm {
    color: var(--app-text-color-primary) !important ;
    font-family: var(--font-secondary) !important ;
    font-size: var(--app-font-sm) !important ;
    font-weight: var(--app-font-weight-semi) !important ;
  }
  
  .app-heading-medium-primary-md {
    color: var(--app-text-color-primary) !important ;
    font-family: var(--font-primary) !important ;
    font-size: var(--app-font-md) !important ;
    font-weight: var(--app-font-weight-medium) !important ;
  }
  
  .app-heading-medium-primary-sm {
    color: var(--app-text-color-primary) !important ;
    font-family: var(--font-primary) !important ;
    font-size: var(--app-font-sm) !important ;
    font-weight: var(--app-font-weight-medium) !important ;
  }
  
  .app-heading-tp-md {
    color: var(--app-text-color-tertiary) !important ;
    font-family: var(--font-primary) !important ;
    font-size: var(--app-font-md) !important ;
    font-weight: var(--app-font-weight-bold) !important ;
  }
  
  .app-heading-medium-tp-sm {
    color: var(--app-text-color-tertiary) !important ;
    font-family: var(--font-primary) !important ;
    font-size: var(--app-font-sm) !important ;
    font-weight: var(--app-font-weight-medium) !important ;
  }
  
  .app-heading-semi-sp-sm {
    color: var(--app-text-color-secondary) !important ;
    font-family: var(--font-primary) !important ;
    font-size: var(--app-font-sm) !important ;
    font-weight: var(--app-font-weight-semi) !important ;
  }
  
  .app-heading-semi-sp-md {
    color: var(--app-text-color-secondary) !important ;
    font-family: var(--font-primary) !important ;
    font-size: var(--app-font-md) !important ;
    font-weight: var(--app-font-weight-semi) !important ;
  }
  
  .app-heading-semi-ts-md {
    color: var(--app-text-color-tertiary) !important ;
    font-family: var(--font-secondary) !important ;
    font-size: var(--app-font-md) !important ;
    font-weight: var(--app-font-weight-semi) !important ;
  }
  
  .app-heading-semi-ts-sm {
    color: var(--app-text-color-tertiary) !important ;
    font-family: var(--font-secondary) !important ;
    font-size: var(--app-font-sm) !important ;
    font-weight: var(--app-font-weight-semi) !important ;
  }
  
  .app-heading-semi-ts-xs {
    color: var(--app-text-color-tertiary) !important ;
    font-family: var(--font-secondary) !important ;
    font-size: var(--app-font-xs) !important ;
    font-weight: var(--app-font-weight-semi) !important ;
  }
  
  .app-heading-sp-xl {
    color: var(--app-text-color-secondary) !important ;
    font-family: var(--font-primary) !important ;
    font-size: var(--app-font-xl) !important ;
    font-weight: var(--app-font-weight-bold) !important ;
  }
  
  .app-heading-sp-lg {
    color: var(--app-text-color-secondary) !important ;
    font-family: var(--font-primary) !important ;
    font-size: var(--app-font-lg) !important ;
    font-weight: var(--app-font-weight-bold) !important ;
  }
  
  .app-heading-semi-secondary-sm {
    color: var(--app-text-color-secondary) !important ;
    font-family: var(--font-secondary) !important ;
    font-size: var(--app-font-sm) !important ;
    font-weight: var(--app-font-weight-semi) !important ;
  }
  
  .app-heading-medium-secondary-md {
    color: var(--app-text-color-secondary) !important ;
    font-family: var(--font-secondary) !important ;
    font-size: var(--app-font-md) !important ;
    font-weight: var(--app-font-weight-medium) !important ;
  }
  .app-heading-medium-secondary-sm {
    color: var(--app-text-color-secondary) !important ;
    font-family: var(--font-secondary) !important ;
    font-size: var(--app-font-sm) !important ;
    font-weight: var(--app-font-weight-medium) !important ;
  }
  
  .app-heading-ts-sm {
    color: var(--app-text-color-tertiary) !important ;
    font-family: var(--font-secondary) !important ;
    font-size: var(--app-font-sm) !important ;
    font-weight: var(--app-font-weight-normal) !important ;
  }
  
  .app-heading-medium-ps-sm {
    color: var(--app-text-color-primary) !important ;
    font-family: var(--font-secondary) !important ;
    font-size: var(--app-font-sm) !important ;
    font-weight: var(--app-font-weight-medium) !important ;
  }
  
  .app-heading-semi-secondary-xs {
    color: var(--app-text-color-secondary) !important ;
    font-family: var(--font-secondary) !important ;
    font-size: var(--app-font-xs) !important ;
    font-weight: var(--app-font-weight-semi) !important ;
  }
  
  .app-heading-ts-xs {
    color: var(--app-text-color-tertiary) !important ;
    font-family: var(--font-secondary) !important ;
    font-size: var(--app-font-xs) !important ;
    font-weight: var(--app-font-weight-normal) !important ;
  }
  
  .app-heading-semi-primary-md {
    color: var(--app-text-color-primary) !important ;
    font-family: var(--font-primary) !important ;
    font-size: var(--app-font-md) !important ;
    font-weight: var(--app-font-weight-semi) !important ;
  }
  
  .app-heading-medium-primary-xl {
    color: var(--app-text-color-primary) !important ;
    font-family: var(--font-primary) !important ;
    font-size: var(--app-font-xl) !important ;
    font-weight: var(--app-font-weight-medium) !important ;
  }
  
  .app-heading-medium-tp-md {
    color: var(--app-text-color-tertiary) !important ;
    font-family: var(--font-primary) !important ;
    font-size: var(--app-font-md) !important ;
    font-weight: var(--app-font-weight-medium) !important ;
  }
  
  .app-heading-medium-tp-sm {
    color: var(--app-text-color-tertiary) !important ;
    font-family: var(--font-primary) !important ;
    font-size: var(--app-font-sm) !important ;
    font-weight: var(--app-font-weight-medium) !important ;
  }
  
  .app-heading-medium-tp-xs {
    color: var(--app-text-color-tertiary) !important ;
    font-family: var(--font-primary) !important ;
    font-size: var(--app-font-xs) !important ;
    font-weight: var(--app-font-weight-medium) !important ;
  }
  
  .app-heading-ws-2xl {
    color: white;
    font-family: var(--font-secondary) !important ;
    font-size: var(--app-font-2xl) !important ;
    font-weight: var(--app-font-weight-bold) !important ;
  }
  
  /* text */
  
  .app-text-ds-xs {
    color: var(--app-text-color-danger) !important ;
    font-family: var(--font-secondary) !important ;
    font-size: var(--app-font-xs) !important ;
  }
  
  .app-text-secondary-sm {
    color: var(--app-text-color-secondary) !important ;
    font-family: var(--font-secondary) !important ;
    font-size: var(--app-font-sm) !important ;
  }
  
  .app-text-ts-sm {
    color: var(--app-text-color-tertiary) !important ;
    font-family: var(--font-secondary) !important ;
    font-size: var(--app-font-sm) !important ;
  }
  
  .app-text-secondary-xs {
    color: var(--app-text-color-secondary) !important ;
    font-family: var(--font-secondary) !important ;
    font-size: var(--app-font-xs) !important ;
  }
  
  .app-text-ps-xs {
    color: var(--app-text-color-primary) !important ;
    font-family: var(--font-secondary) !important ;
    font-size: var(--app-font-xs) !important ;
  }
  
  .app-text-secondary-md {
    color: var(--app-text-color-secondary) !important ;
    font-family: var(--font-secondary) !important ;
    font-size: var(--app-font-md) !important ;
  }
  
  .app-text-ps-md {
    color: var(--app-text-color-primary) !important ;
    font-family: var(--font-secondary) !important ;
    font-size: var(--app-font-md) !important ;
  }
  
  .app-text-ts-xs {
    color: var(--app-text-color-tertiary) !important ;
    font-family: var(--font-secondary) !important ;
    font-size: var(--app-font-xs) !important ;
  }
  
  .app-text-ps-sm {
    color: var(--app-text-color-primary) !important ;
    font-family: var(--font-secondary) !important ;
    font-size: var(--app-font-sm) !important ;
  }
  
  .app-text-ws-md {
    color: white;
    font-family: var(--font-secondary) !important ;
    font-size: var(--app-font-md) !important ;
  }
  
  .app-text-ts-md {
    color: var(--app-text-color-tertiary) !important ;
    font-family: var(--font-secondary) !important ;
    font-size: var(--app-font-md) !important ;
  }
  