.employer-profile-image{
    height: 300px;
    width: 300px;
    border-radius: 50%;
    /* box-shadow: 0px -2px 20px 2px rgba(196, 195, 195, 0.753); */
    margin: 30px;
}

.employer-profile-title{
    text-align: left;
    font-size: 52px;
    text-transform: capitalize;
    margin-top: 1.5rem;
}

.employer-profile-icons{
    height: 45px;
}

.icon-with-text > img, .icon-with-text > p{
    display: inline-block;
}

.icon-with-text{
    font-size: 20px;
    color: #000;
    line-height: 2;
    word-wrap: break-word;
    overflow: hidden;

    vertical-align: middle;
}

.text-section{
    text-align: left;
    display: flex;
  flex-direction: column;
  justify-content: center;    
  top: 30;
}

.employer-follow-button > img{
    height: 30px;
}

.employer-follow-button > span{
    font-size: 20px;
    color: #259EF4;
}

.employer-follow-button{
    border: 1px solid #259EF4;
    padding: 20px 30px;
    border-radius: 10px;
}

.employer-copy-button{
  border: 1px solid #259EF4;
    padding: 20px 30px;
    border-radius: 10px;
    margin: 20px;
    float: right;
}
.employer-copy-button img{
  height: 30px;
}

.employer-copy-button span{
  font-size: 20px;
  color: #259EF4;
}

.follow-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.follower-counter-section{
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.counter{
    margin-bottom: 0px;
}

.employer-profile-sidebar-icons{
    height: 50px;
}

  .employer-sidebar-icon-box {
    display: table;
    position: relative;
    max-width: 30rem;
    /* padding: 1em 1em 1em 0; */
  }

  .icon-table{
    text-align: center;
    width: 30%;
  }

  .text-table{
    text-align: left;
    width: 70%;
  }

  .section-container{
    text-align: left;
    padding: 40px;
    display: flex;
    justify-content: space-between;
  }

  .icon-table-cover{
    text-align: left;
    width: 20%;
    padding: 0px;
  }

  .text-table-cover{
    text-align: left;
    color: #000;
    font-size: 22px;
    width: 80%;
    padding: 0px;
  }

  .employer-update-button{
    background-color: #2A9DF4;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    float: right;
    cursor: pointer;
  }

  .discard-button{
    background-color: #2b3940;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    float: right;
    margin-right: 20px;
    cursor: pointer;
  }

  .form-group > label{
    color: #000;
  }

  .youtube-video{
    width: 100%;
    height: 500px;
  }

  .employer-social-container{
    margin: 30px !important;
  }

  .employer-social-icon img{
    height: 60px !important;
  }
  /* Mobile Views  */

  @media only screen and (max-width: 600px) {
    .youtube-video{
        width: 100%;
        height: 200px;
      }

    .employer-profile-image{
        height: 150px;
        width: 150px;
        border-radius: 50%;
        /* box-shadow: 0px -2px 20px 2px rgba(196, 195, 195, 0.753); */
        margin: 10px;
    }

    .employer-profile-title{
        text-align: left;
        font-size: 32px;
        text-transform: capitalize;
    }

    .employer-follow-button > img{
        height: 20px;
    }
    
    .employer-follow-button > span{
        font-size: 15px;
        color: #259EF4;
    }
    
    .employer-follow-button{
        border: 1px solid #259EF4;
        padding: 10px 15px;
        border-radius: 5px;
    }
    
  }