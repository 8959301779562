@import url('https://fonts.googleapis.com/css2?family=Epilogue:ital,wght@0,100..900;1,100..900&display=swap');
/*================== 
theme color 
==================*/
:root {
    --primary-color: #3b5997;
    --secondary-color: #f0a933;
    --title-color: #000000;
    --text-color-1: #1e1e1e;
    --text-color-2: #fcfcfd;
}

.containers {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Epilogue', sans-serif;
}

/* banner css start */
.bannerContanier {
    height: 970px;
}

.titleText {
    font-size: 36px;
    font-weight: 700;
    line-height: 50px;
    font-family: 'Epilogue', sans-serif;
    color: var(--text-color-2);
    line-height: 56px;
}

.contentText {
    font-size: 16px;
    font-weight: 600;
    color: var(--text-color-1);
    line-height: 26px;
}
.btnsLink {
    background-color: #007bff;
    color: var(--text-color-2) !important;
    border: none;
    border-radius: 40px;
    padding: 15px 40px;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
}
.btnsLink > img {
    max-width: 20px;
}
.btnPadding {
    padding-top: 2rem;
    display: flex;
    justify-content: center;
}
.bannerImage {
    height: 100%;
    width: 100%;
}
.videos {
    background-color: #fff;
    width: 80%;
    height: 500px;
    border-radius: 40px;
    padding: 10px;
    border: 1px solid #d3d3d3;
    position: absolute;
    top: -230px;
    left: 10%;
    right: 10%;
}
.vedioLink {
    width: 100%;
    height: 100%;
    border-radius: 40px;
}
/* banner css end */

/* slider css start */
.sliderGrid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 1rem;
    padding-bottom: 6rem;
    margin-top: 3rem;
}
.sliderCard {
    background-color: #ffffff;
    padding: 10px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sliderCard > img {
    max-width: 100px;
    max-height: 50px;
    object-fit: cover;
}
.viewLink {
    color: #232233;
    text-decoration: underline;
}
.titleColor {
    color: var(--text-color-1);
}
.activeContent {
    color: #3b5997;
    font-size: 20px;
    font-weight: 700;
}
/* slider css end */

/* review css start */
.reviewContainer {
    padding-bottom: 6rem;
}
.techContainerFlex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.jobGird {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin-top: 3rem;
}
.userCardReview {
    background-color: #fff;
    padding: 30px 10px;
    border-radius: 10px;
}
.userCardReview > img {
    width: 100%;
    border-radius: 10px;
}

.viewDetials {
    background-color: #007bff;
    color: var(--text-color-2);
    font-size: 16px;
    font-weight: 600;
    border-radius: 30px;
    padding: 13px 25px;
    width: 23%;
    text-align: center;
}
.TechContainer {
    width: 100%;
    background-image: url('../../images/lp4/Bg/tech.png');
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    padding: 3.5rem 0rem 1.5rem;
    margin-bottom: 6rem;
}
.techTitle {
    width: 70%;
}

/* job css end */

/* user css start */
.userContainer {
    width: 100%;
    background-image: url('../../images/lp4/Bg/user_img.png');
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    padding-bottom: 6rem;
    padding-top: 6rem;
    height: 600px;
}

/* Slider.module.css */
.testimonialContainer {
    padding-bottom: 6rem;
}

.testimonialGrid {
    margin-top: 3rem;
}

.testimonialCard {
    background-color: #fff;
    border-radius: 10px;
    padding: 2rem;
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.testimonialsFlex {
    display: flex;
    flex-wrap: nowrap;
    transition: transform 0.5s ease;
}
.sliderPagination {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
}

.paginationDot {
    display: inline-block;
    width: 10px;
    height: 10px;
    background: #112848;
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.3s;
}

.paginationDot.active {
    background: var(--primary-color);
}
.clientReviewCard {
    height: 70px;
    width: 70px;
    border-radius: 50%;
}

.clientReviewCard > img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
}
/* user css end */
/* footer added */
.boxs {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 90px;
    background-color: #3b5997;
    border-radius: 0;
    z-index: 100000;
    color: white;
    transition: bottom 0.5s ease-in-out;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.buttons {
    background: #ffa900;
    text-transform: capitalize;
    font-family: 'Epilogue', sans-serif;
    font-weight: 700;
    font-size: 20px;
    max-width: 500px;
    padding: 16px 48px;
    border: 0;
    border-radius: 40px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s ease-out;
}

.buttons:hover {
    background-color: #ff9900;
    transform: scale(1.05);
}

/* small device  */

@media only screen and (min-width: 250px) and (max-width: 575px) {
    .containers {
        max-width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .activeContent {
        text-align: center;
    }
    .textCenters {
        text-align: center;
    }
    .btnPadding {
        display: flex;
        justify-content: center;
    }
    .sliderGrid {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .jobGird {
        grid-template-columns: 1fr;
    }
    .userWidth {
        width: 100%;
    }
    .clientReviewCard {
        height: 70px;
        width: 70px;
        border-radius: 50%;
    }

    .clientReviewCard > img {
        height: 100%;
        width: 100%;
        border-radius: 50%;
        object-fit: cover;
        object-position: top;
    }
    .videos {
        height: 300px;
        top: -140px;
    }
    .bannerContanier {
        height: 720px;
    }
    .techContainerFlex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        gap: 1rem;
    }
    .viewDetials {
        width: 250px;
        margin-left: auto;
        margin-right: auto;
    }
    .techTitle {
        width: 100%;
    }
    .titleText {
        font-size: 26px;
        font-weight: 700;
        line-height: 50px;
        font-family: 'Epilogue', sans-serif;
        color: var(--text-color-2);
        line-height: 38px;
    }
    .userContainer {
        height: 470px;
    }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
    .bannerFlex {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 2rem;
    }
    .bannerFlexLeft {
        width: 100%;
    }
    .bannerFlexRight {
        width: 100%;
    }
    .containers {
        max-width: 100%;
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .activeContent {
        text-align: center;
    }
    .textCenters {
        text-align: center;
    }
    .btnPadding {
        display: flex;
        justify-content: center;
    }
    .sliderGrid {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .jobGird {
        grid-template-columns: 1fr;
    }
    .userWidth {
        width: 100%;
    }
    .clientReviewCard {
        height: 70px;
        width: 70px;
        border-radius: 50%;
    }

    .clientReviewCard > img {
        height: 100%;
        width: 100%;
        border-radius: 50%;
        object-fit: cover;
        object-position: top;
    }
    .techContainerFlex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        gap: 1rem;
    }
    .viewDetials {
        width: 250px;
        margin-left: auto;
        margin-right: auto;
    }
    .techTitle {
        width: 100%;
    }
    .titleText {
        font-size: 26px;
        font-weight: 700;
        line-height: 50px;
        font-family: 'Epilogue', sans-serif;
        color: var(--text-color-2);
        line-height: 38px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .containers {
        max-width: 100%;
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .activeContent {
        text-align: center;
    }
    .bannerFlex {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 2rem;
    }
    .bannerFlexLeft {
        width: 100%;
    }
    .bannerFlexRight {
        width: 100%;
    }
    .textCenters {
        text-align: center;
    }
    .btnPadding {
        display: flex;
        justify-content: center;
    }
    .sliderGrid {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .jobGird {
        grid-template-columns: 1fr 1fr;
    }
    .userWidth {
        width: 100%;
    }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .containers {
        max-width: 100%;
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .jobGird {
        grid-template-columns: 1fr 1fr;
    }
}
