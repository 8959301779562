.roundedImage {
    width: 70px;
    height: 70px;
    background-color: #6b6b6b7d;
    border-radius: 50%;
    min-width: 70px;
    min-height: 70px;
    filter: blur(4px);
    -webkit-filter: blur(4px);
}

.commonMasking{
    filter: blur(4px);
    -webkit-filter: blur(4px);
}