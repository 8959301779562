.active {
    display: block !important;
}

.inactive {
    display: none !important;
}

.companyListPageRoot {
    position: relative;
    display: flex;
}

.sideBarCloseIcon{
    display: none;
    position: absolute;
    top: 11px;
    right: 3px;
    background: red;
    width: 30px;
    height: 30px;
    color: white;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
}

.filterFormSideBar {
    position: sticky;
    top: 100px;
    left: 0px;
    width: 350px;
    min-width: 350px;
    background: #F2F2F2;
    box-shadow: 0px 2px 27px rgba(0, 0, 0, 0.25);
    height: calc(100vh - 100px);
    padding: 20px;
}

.filterFormContainer{
    background: white;
    border-radius: 10px;
    border: 1px #CCCCCC solid;
    padding: 15px;
    margin-top: 10px;
    
}

.listContainer {
    min-height: calc(100vh - 100px);
    padding: 50px;
    width: 100%;
}

.fliterIconContainer{
    width: 32px;
    height: 32px;
    margin-left: 8px;
    background-color: #B6DDFF;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 50%;
}

@media screen and (max-width: 768px) {

    .filterFormSideBar{
        position: fixed;
        z-index: 10;
    }

    .listContainer {
        padding: 20px;
    }

    .sideBarCloseIcon{
        display: flex;
    }
}