.card-section {
    padding: 20px;
}

.employer-card-image{
    max-height: 100px;
    width: 100px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.employer-profession-title{
    margin: 10px;
    font-size: 30px;
    font-weight: 300;
    color: black;
}

.employer-name{
    margin: 10px;
    font-size: 22px;
    font-weight: 600;
    color: black;
}

.employer-tags {
    font-size: 12px;
    background-color: rgb(20, 20, 128);
    color: white;
    border-radius: 8px;
    display: inline-block;
    margin: 5px;
    padding: 5px 15px; 
}

.employer-description {
    font-size: 18px;
    color :rgb(51, 51, 68);
    /* width: 12em; */
    padding: 10px;
}

.employer-apply-button {
    padding : 20px 10px;
    font-size: 12px;
    background-color: rgb(0, 110, 255);
    color: white;
    text-transform: uppercase;
    border-radius: 8px;
    border: 1px solid rgb(0, 110, 255);
    width: 48%;
    cursor: pointer;
}

.employer-save-button {
    padding : 20px 10px;
    font-size: 12px;
    background-color: white;
    color: rgb(0, 110, 255);
    text-transform: uppercase;
    border-radius: 8px;
    border: 1px solid rgb(0, 110, 255);
    width: 48%;
    cursor: pointer;
    
}