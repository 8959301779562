@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --primary-color: #00457c;
    --title-color: #605c78;
    --bg: #f9fbfd;
}

.body {
    background-color: var(--bg);
}

.containers {
    max-width: 1240px;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Poppins', sans-serif !important;
}

.paddingXY {
    padding-top: 5rem;
    padding-bottom: 6rem;
}

.flexContainer {
    display: flex;
    align-items: center;
    gap: 3rem;
}
.flexContainerLeft {
    width: 50%;
}
.flexContainerRight {
    width: 50%;
}

.fontSize {
    font-size: 16px;
    color: #605c78;
    font-family: 'Poppins', sans-serif !important;
}

.bannerTextMargin {
    margin-top: 2.5rem;
    margin-bottom: 3rem;
}

.buttons {
    border: 1px solid var(--primary-color);
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 87px;
    padding: 13px 25px;
}

.titles {
    color: var(--primary-color);
    font-size: 32px;
    line-height: 48px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif !important;
}
.bannerTitle {
    color: var(--primary-color);
    font-size: 48px;
    line-height: 56px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif !important;
}
.buttonFlex {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.hiringGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
}
.hiringCard {
    padding: 15px;
    border-radius: 24px;
}
.Gap {
    gap: 6rem !important;
}
.flexContainerLeft1 {
    width: 55%;
}
.flexContainerRight1 {
    width: 45%;
}
.paddingXY1 {
    padding-top: 6rem;
    padding-bottom: 6rem;
}

.companyGrid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 1rem;
    margin-top: 4rem;
}
.companyCard {
    background-color: #fff;
    padding: 10px;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}
.ExperienceGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin-top: 4rem;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}
.ExperienceCard1 {
    padding: 40px;
    border-radius: 26px;
    border: 1px solid #e7ebff;
    background-color: white;
    box-shadow: 0px 26px 40px rgba(188, 202, 255, 0.13);
}
.ExperienceCard2 {
    padding: 40px;
    border-radius: 26px;
    background-image: url('../../images/lp7/image/PremiumBg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid #e7ebff;
    width: 100%;
    box-shadow: 0px 26px 40px rgba(188, 202, 255, 0.13);
}
.BestPadding {
    padding: 3.5rem 3.5rem 3.5rem;
}
.bestContainer {
    margin-top: 6rem;
    margin-bottom: 6rem;
}

/* footer added with floating */
.boxs {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 90px;
    background-color: #3b5a9700;
    border-radius: 0;
    z-index: 100000;
    color: white;
    transition: bottom 0.5s ease-in-out;
    /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25); */
}

/* Button with glowing border loop */
.Fbuttons {
    background: #00457c;
    text-transform: capitalize;
    font-family: 'Epilogue', sans-serif;
    color:#fff;
    font-weight: 700;
    font-size: 20px;
    max-width: 500px;
    padding: 16px 48px;
    border: 0px solid transparent; /* Initial border */
    border-radius: 40px;
    cursor: pointer;
    position: relative;
    z-index: 1;
    overflow: hidden; /* Ensure the glow stays within the button */
}

.Fbuttons::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(120deg, #00457c, #236BFE);
    border-radius: 40px;
    z-index: -1; /* Position behind the button content */
    animation: glow-border 3s linear infinite;
}

@keyframes glow-border {
    0% {
        transform: translate(-50%, -50%);
    }
    100% {
        transform: translate(0%, 0%);
    }
}


/* small device  */

@media only screen and (min-width: 250px) and (max-width: 575px) {
    .containers {
        max-width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .flexContainer {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 2rem;
    }
    .flexContainerLeft {
        width: 100%;
    }
    .flexContainerRight {
        width: 100%;
    }
    .buttonFlex {
        display: flex;
        flex-direction: column;
    }
    .bannerTitle {
        color: var(--primary-color);
        font-size: 28px;
        line-height: 32px;
        font-weight: 700;
        text-align: center;
        font-family: 'Poppins', sans-serif !important;
    }
    .bannerTextMargin {
        margin-top: 2rem;
        margin-bottom: 2.5rem;
        text-align: center;
    }
    .flexContainerLeft1 {
        width: 100%;
    }
    .flexContainerRight1 {
        width: 100%;
    }
    .titles {
        text-align: center;
    }
    .hiringGrid {
        grid-template-columns: 1fr;
    }
    .smallText {
        font-size: 16px;
        text-align: center;
    }
    .companyGrid {
        grid-template-columns: repeat(3, 1fr);
    }
    .ExperienceGrid {
        width: 100%;
        grid-template-columns: 1fr;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .containers {
        max-width: 100%;
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .flexContainer {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 2rem;
    }
    .flexContainerLeft {
        width: 100%;
    }
    .flexContainerRight {
        width: 100%;
    }
    .buttonFlex {
        display: flex;
        flex-direction: column;
    }
    .bannerTitle {
        color: var(--primary-color);
        font-size: 28px;
        line-height: 32px;
        font-weight: 700;
        text-align: center;
        font-family: 'Poppins', sans-serif !important;
    }
    .bannerTextMargin {
        margin-top: 2rem;
        margin-bottom: 2.5rem;
        text-align: center;
    }

    .flexContainerLeft1 {
        width: 100%;
    }
    .flexContainerRight1 {
        width: 100%;
    }
    .titles {
        text-align: center;
    }
    .hiringGrid {
        grid-template-columns: 1fr;
    }
    .smallText {
        font-size: 16px;
        text-align: center;
    }
    .companyGrid {
        grid-template-columns: repeat(4, 1fr);
    }
    .ExperienceGrid {
        width: 100%;
        grid-template-columns: 1fr;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .containers {
        max-width: 100%;
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .flexContainer {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 3rem;
    }
    .flexContainerLeft {
        width: 100%;
    }
    .flexContainerRight {
        width: 100%;
    }
    .flexContainerLeft1 {
        width: 100%;
    }
    .flexContainerRight1 {
        width: 100%;
    }
    .companyGrid {
        grid-template-columns: repeat(4, 1fr);
    }
    .ExperienceGrid {
        width: 100%;
        grid-template-columns: 1fr;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1239px) {
    .containers {
        max-width: 100%;
        padding-left: 2rem;
        padding-right: 2rem;
    }
}
