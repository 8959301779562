.messageBox {
  border: 1px solid;
  border-radius: 5px;
}

.messageBoxHeader {
  padding: 10px;
  /* background-color: #eff2f8; */
  border-radius: 5px 5px 0px 0px;
}

.userPicContainer {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  box-shadow: 1px 3px 5px #00000040;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.userPicContainer > * {
  width: 80%;
  height: 80%;
  border-radius: 50%;
}

.messageBoxHeaderName {
  margin-left: 20px;
}

.messageBoxBody {
  padding: 20px;
  padding-top: 50px;
  /* height: calc(100vh - 500px); */
  overflow-y: auto;
}

.messageSenderContainer {
  display: flex;
  align-items: end;
  margin-bottom: 20px;
}

.senderMessages {
  margin-left: 15px;
  max-width: 70%;
}

.senderSingleMessage {
  padding: 10px;
  border-radius: 10px 10px 10px 0px;
  background-color: #f0f6ff;
  color: #1d1e1b;
  font-family: var(--app-font-secondary);
  font-size: 12px;
  margin-bottom: 5px;
  width: fit-content;
}

.senderSingleMessage a {
  font-weight: bold;
}

.messageOwnContainer {
  display: flex;
  align-items: end;
  justify-content: end;
  margin-bottom: 20px;
}

.ownMessages {
  margin-right: 15px;
  max-width: 70%;
}

.ownSingleMessageContainer {
  display: flex;
  justify-content: end;
}

.ownSingleMessage {
  padding: 10px;
  border-radius: 10px 10px 0px 10px;
  background-color: #0275d8;
  color: white;
  font-family: var(--app-font-secondary);
  font-size: 12px;
  margin-bottom: 5px;
  width: fit-content;
}

.ownSingleMessage a {
  color: white;
  font-weight: bold;
}

.footer {
  /* padding: 25px 15px; */
}

.sendInputContainer {
  border: 1px solid;
  padding: 8px 5px;
  /* border-radius: 5px; */
}

.sendInputForm {
  display: flex;
}

.sendInputForm > * {
  margin: 0px 5px;
}

.sendInput {
  width: -webkit-fill-available;
  width: -moz-available;
  border: none;
  background-color: transparent;
}

.sendInput:focus {
  outline: none;
}

.sendInputFormFileLabel {
  height: 40px;
  width: 50px;
  border-radius: 4px;
  background-color: #bddfff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.fileNameContainer {
  display: flex;
  padding: 5px 20px;
  background-color: #c9c9c9;
  margin-bottom: 5px;
  width: fit-content;
  border-radius: 19px;
  color: #333333;
  align-items: center;
}

.fileNameClose {
  margin-left: 10px;
  font-size: 19px;
  color: white;
  height: 21px;
  width: 21px;
  background-color: red;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.loader {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  position: relative;
  background: #fff;
  box-shadow: -24px 0 #fff, 24px 0 #fff;
  box-sizing: border-box;
  animation: shadowPulse 2s linear infinite;
}

@keyframes shadowPulse {
  33% {
    background: #fff;
    box-shadow: -24px 0 #0275d8, 24px 0 #fff;
  }
  66% {
    background: #0275d8;
    box-shadow: -24px 0 #fff, 24px 0 #fff;
  }
  100% {
    background: #fff;
    box-shadow: -24px 0 #fff, 24px 0 #0275d8;
  }
}
