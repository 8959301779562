    
  .marquee-section{
    background-color: #f2faff;
    overflow: hidden;
  }
  
  
  .marquee-content {
    display: flex;
    animation: loopSlide 20s infinite linear 0s both;
    height: 200px;
    align-items: center;
    justify-content: center;
  }

  .marquee-item {
    flex: 0 0 14vw;
    height: 120px !important;
    width: 200px !important;
    background-color: #fff !important;
    border-radius: 10px;
    text-align: center !important;
    margin-left: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 50px 0px rgb(196 195 195 / 40%) !important;
  }
  
  .marquee-item img {
    /* display: block; */
    /* width: 100%; */
  /*   padding: 0 20px; */
  height: 80px;
  /* margin-top: 25px; */
  }
  
  @keyframes loopSlide {
    0% { 
      -webkit-transform: (0);
      transform: translateX(0); 
    }
    100% { 
      -webkit-transform: (-100%);
      transform: translatex(-100%);
    }
  }

  @media only screen and (max-width: 768px) {
    .marquee-item{
      flex: 0 0 40vw;
    }
  }
  
