
.cv-manager-heading{
    color: #1167B1;
    text-align: left !important;
}

.upload-box{
    width: 100%;
    border: 2px dashed rgb(13, 98, 155);
    height: 150px;
    border-radius: 5px;
}

.cv-icons{
    height: 60px;
    width: 60px;
    margin: 20px;
    cursor: pointer;
}
.upload-loader{
    height: 148px;
    /* width: 310px; */
}

.upload-box>input {
    display: none;
  }

.upload-button {
    padding : 10px 8px;
    font-size: 10px;
    background-color: rgb(0, 110, 255);
    color: white;
    text-transform: uppercase;
    border-radius: 5px;
    border: 1px solid rgb(0, 110, 255);
    width: 80%;
    margin: 20px;
}
.upload-button:hover{
    color: white;
}
.disbaled-upload-button{
    padding : 10px 8px;
    font-size: 10px;
    background-color: rgb(0, 110, 255);
    color: white;
    text-transform: uppercase;
    border-radius: 5px;
    border: 1px solid rgb(0, 110, 255);
    width: 80%;
    margin: 20px;
    cursor: not-allowed;
}

.middle-text{
    margin: auto;
    white-space: nowrap
}

.resume-title{
    text-align: left;
    font-size: 16px;
    font-weight: 400;
}

.delete-icon{
    color: red;
    float: right;
    margin-top: 10px;
    margin-right: 20px;
    cursor: pointer;
}

.details-button{
    padding : 10px 10px;
    font-size: 10px;
    background-color: rgb(0, 110, 255);
    color: white;
    text-transform: uppercase;
    border-radius: 5px;
    border: 1px solid rgb(0, 110, 255);
}

.delete-button{
    background-color: #fff;
    border: none;
    margin-top: 10px;
    color:rgb(0, 110, 255);;
}

@media only screen and (max-width: 600px) {
    .resume-title{
        text-align: left;
        font-size: 12px;
        font-weight: 400;
        word-wrap: break-word;
    }
    .details-button{
        padding : 10px 10px;
        font-size: 8px;
        background-color: rgb(0, 110, 255);
        color: white;
        text-transform: uppercase;
        border-radius: 5px;
        border: 1px solid rgb(0, 110, 255);
    }
    .resume-card{
        border-radius: 10px !important;
        padding:10px;
        text-align: center;
        margin: 10px;
        box-shadow: 0px -2px 20px 2px rgba(196, 195, 195, 0.753);
    }
}

.resume-card{
    border-radius: 10px !important;
    padding:20px;
    text-align: center;
    margin: 10px;
    box-shadow: 0px -2px 20px 2px rgba(196, 195, 195, 0.753);
}

.file-name{
    margin: 20px 40px;
}

.resume-div{
    border-bottom: 1px solid #DFDFDF;
}

.resume-div:last-child{
    border-bottom: none
}

.upload-title, .upload-text{
    font-family: Epilogue,sans-serif;
}

.responsive-div {
    width: 100%;
}

@media (min-width: 768px) {
    .responsive-div {
        width: 50%;
    }
}