.card-section {
    padding: 20px;
}

.candidate-card-image{
    height: 100px;
    width: 100px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
}

.candidate-profession-title{
    margin: 10px;
    font-size: 30px;
    font-weight: 300;
    color: black;
}

.candidate-name{
    margin: 10px;
    font-size: 22px;
    font-weight: 600;
    color: black;
}

.candidate-tags {
    font-size: 12px;
    background-color: rgb(20, 20, 128);
    color: white;
    border-radius: 8px;
    display: inline-block;
    margin: 5px;
    padding: 5px 15px; 
}

.candidate-description {
    font-size: 18px;
    color :rgb(51, 51, 68);
    /* width: 12em; */
    padding: 10px;
}

.candidate-apply-button {
    padding : 20px 10px;
    font-size: 12px;
    background-color: rgb(0, 110, 255);
    color: white;
    text-transform: uppercase;
    border-radius: 8px;
    border: 1px solid rgb(0, 110, 255);
    width: 48%;
    cursor: pointer;
}

.candidate-view-button{
    padding : 20px 10px;
    font-size: 12px;
    background-color: rgb(0, 110, 255);
    color: white;
    text-transform: uppercase;
    border-radius: 8px;
    border: 1px solid rgb(0, 110, 255);
    width: 98%;
    cursor: pointer;
}

.candidate-save-button {
    padding : 20px 10px;
    font-size: 12px;
    background-color: white;
    color: rgb(0, 110, 255);
    text-transform: uppercase;
    border-radius: 8px;
    border: 1px solid rgb(0, 110, 255);
    width: 48%;
    cursor: pointer;
    
}
.intro-text-container{
    height: 130px !important;
}

.intro-text{
  line-height: 2rem;
  max-height: 8rem;
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  overflow: hidden !important;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
}

.cover-letter-overlay{
    position: fixed;
    /* display: none; */
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 2;
    cursor: pointer;
}

.cover-letter-text{
    position: absolute;
    top: 50%;
    left: 50%;
    /* font-size: 50px; */
    color: #000;
    transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    background-color: rgba(255, 255, 255);
    padding: 20px;
    text-align: left;
    border-radius: 5px;
}

.apply-status__control{
    padding: 10px 10px !important;
    width: 98% !important;
    border: 2px solid #3571f5 !important;
  }
  
  .apply-status__indicator-separator{
    display: none !important;
  }
  
  .apply-status__indicator{
    color: #3571f5 !important;
  }

  .apply_status__menu{
    width: 48% !important;
  }