.footerItemsHeader{
    color: #0275D8;
    font-size: 18px;
    font-family: Epilogue,sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    word-wrap: break-word;
}

.footerList{
    list-style-type: none;
    margin-top: 40px;
    
}

.footerList li {
    font-size: 18px !important;
    font-family: Epilogue,sans-serif;
    font-weight: 400 !important;
    margin-bottom: 15px;
}

.contactList{
    list-style-type: none;
    margin-top: 40px;
}

.contactList li {
    display: flex;
    font-size: 18px !important;
    font-family: Epilogue,sans-serif;
    font-weight: 400 !important;
    margin-bottom: 25px;
    color: white;
    align-items: center;
}


.iconContainer{
    width: 40px;
    height: 40px;
    position: relative;
    border-radius: 50%;
    background-color: #1D1E1B;
    margin-right: 20px;
    flex-shrink: 0;
}

.icon{
    position: absolute;
    top: 8px;
    left: 9px;
    font-size: 40px;
    color: #0275D8;
    
}

