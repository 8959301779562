.employer-card-image{
    max-height: 80px;
    width: 80px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
}

.key-informations{
    top: 30px;
  position: sticky !important;
  padding: 20px;
  overflow: auto;
}


.employer-name{
    margin: 10px;
    font-size: 18px;
    font-weight: 300;
    color: black;
}

.heading{
    /* margin: 10px; */
    font-size: 24px;
    font-weight: 600;
    color: black;
    text-align: center;
    text-transform: uppercase;
}

.details{
    font-size: 18px;
    text-align: left;
    margin-top: 20px;
    color: black;
}

.details p{
    margin-bottom: 20px;
}

.job-apply-button {
    padding : 20px 10px;
    font-size: 12px;
    background-color: rgb(0, 110, 255);
    color: white;
    text-transform: uppercase;
    border-radius: 8px;
    border: 1px solid rgb(0, 110, 255);
    width: 78%;
}

.profile-save-button {
    padding : 20px 10px;
    font-size: 12px;
    background-color: white;
    color: rgb(0, 110, 255);
    text-transform: uppercase;
    border-radius: 8px;
    border: 1px solid rgb(0, 110, 255);
    width: 18%;
    cursor: pointer;
    
}